<div class="container">
    <div class="row">
        <div class="col-lg-3 col-sm-12">
            <!-- profile header -->
            <div class="d-flex flex-column">
                @if (currentUser && currentUser.imageUrl) {
                    <img class="user-settings-container-profile-picture rounded-3" [src]="currentUser.imageUrl" alt="User Profile Picture" />
                } @else {
                    <fa-icon id="avatar" size="3x" [icon]="faUser" />
                }
                @if (currentUser) {
                    <div>
                        @if (currentUser.name) {
                            <span id="user-header">{{ currentUser.name }}</span>
                        }
                        <br />
                        <span id="login">{{ currentUser.login }}</span>
                    </div>
                }
            </div>
            <!-- links to the different settings -->
            <section id="navigation-bar" class="list-group d-block pt-2">
                <span class="list-group-item disabled fw-bold" jhiTranslate="artemisApp.userSettings.userSettings"></span>
                <a class="list-group-item btn btn-outline-primary" routerLink="account" routerLinkActive="active" jhiTranslate="artemisApp.userSettings.accountInformation"></a>
                <a
                    class="list-group-item btn btn-outline-primary"
                    routerLink="notifications"
                    routerLinkActive="active"
                    jhiTranslate="artemisApp.userSettings.notificationSettings"
                ></a>
                <a class="list-group-item btn btn-outline-primary" routerLink="science" routerLinkActive="active" jhiTranslate="artemisApp.userSettings.scienceSettings"></a>
                @if (localVCEnabled) {
                    <a class="list-group-item btn btn-outline-primary" routerLink="ssh" routerLinkActive="active" jhiTranslate="artemisApp.userSettings.sshSettings"></a>
                    <a
                        class="list-group-item btn btn-outline-primary"
                        routerLink="vcs-token"
                        routerLinkActive="active"
                        jhiTranslate="artemisApp.userSettings.vcsAccessTokenSettings"
                    >
                    </a>
                }
                <a class="list-group-item btn btn-outline-primary" routerLink="ide-preferences" routerLinkActive="active">
                    <!--Default IDE Settings-->
                    <span jhiTranslate="artemisApp.userSettings.categories.IDE_PREFERENCES"></span>
                </a>
            </section>
        </div>
        <!-- the currently opened settings -->
        <div class="col-lg-8 col-sm-12">
            <section id="current-settings">
                <router-outlet />
            </section>
        </div>
    </div>
</div>
